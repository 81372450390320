import { FooterHome } from "../../components/storeHome/FooterHome";
import { Whats } from '../../components/whats/Whats';

export function FooterHomePage(){
      return(
        <>
        <FooterHome/>
        <Whats/>
        </>
    )
}